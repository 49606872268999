<template>
  <div id="agent-report" class="container-fluid">

    <h3>ยอดเว็บไซต์/เอเย่นต์</h3>
    <CardDateFrom :branches="[]" @selected="searchData" />

    <div v-if="items.length > 0">
      <span class="text-primary">ข้อมูลวันที่ {{dataDateText}}</span>
      <div class="card mb-3 py-3">
        <bar-chart :chartdata="chartCollection" :options="chartOptions"></bar-chart>
        <div class="text-center mt-4">
          <b-form-checkbox-group
            v-model="chartSelected"
            :options="chartSelectOptions"
            name="chart-select"
          ></b-form-checkbox-group>
        </div>
      </div>
    </div>

    <div v-if="isManager" class="alert alert-info">
      <b-form-group v-if="branchOptions.length" class="mb-1" label="เลือกดูทีม:" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="branchFilters"
          :options="branchOptions"
          :aria-describedby="ariaDescribedby"
          name="branchFilters"
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <div class="alert alert-info">
      <b-form-group v-if="webOptions.length" class="mb-1" label="เลือกดูเว็บไซต์:" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="webFilters"
          :options="webOptions"
          :aria-describedby="ariaDescribedby"
          name="webFilters"
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <span class="text-primary">ข้อมูลวันที่ {{dataDateText}}</span>
    <div class="card">
      <table class="table table-sm table-bordered mb-0">
        <thead>
          <tr>
            <th rowspan="2" class="text-center">รหัสเอเย่นต์</th>
            <th colspan="3" class="text-center">ยอดเว็บ</th>
            <th v-if="isManager" colspan="4" width="28%" class="text-center">เอเย่นต์</th>
            <th v-if="isManager" colspan="4" width="28%" class="text-center">บริษัทฯ</th>
            <th v-if="isManager" colspan="2" width="14%" class="text-center">สรุปได้-เสีย</th>
          </tr>
          <tr>
            <td width="7%" class="text-center">ยอดขาย</td>
            <td width="7%" class="text-center">ยอดจ่าย</td>
            <td width="7%" class="text-center">คงเหลือ</td>

            <td v-if="isManager" width="7%" class="text-center">ถือหุ้น</td>
            <td v-if="isManager" width="7%" class="text-center">ถูกรางวัล</td>
            <td v-if="isManager" width="7%" class="text-center">คอมฯ</td>
            <td v-if="isManager" width="7%" class="text-center">รวม</td>

            <td v-if="isManager" width="7%" class="text-center">ถือหุ้น</td>
            <td v-if="isManager" width="7%" class="text-center">ถูกรางวัล</td>
            <td v-if="isManager" width="7%" class="text-center">คอมฯ</td>
            <td v-if="isManager" width="7%" class="text-center">รวม</td>

            <td v-if="isManager" width="7%" class="text-center">Company</td>
            <td v-if="isManager" width="7%" class="text-center">สุทธิ</td>
          </tr>
        </thead>
        <tbody v-for="(group, gIndex) in groupItems">
          <tr>
            <td :colspan="isManager?14:4" class="text-primary">เว็บ {{webname(group[0]._id.webId)}}</td>
          </tr>
          <tr v-for="(item, index) in group">
            <td class="text-center">{{ item._id.agentName }}</td>
            <td class="text-right"><span v-html="numberFormat(item.sum_sales)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.sum_won)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.sum_balance)"></span></td>

            <td v-if="isManager" class="text-right"><span v-html="numberFormat(item.sum_agentHold)"></span></td>
            <td v-if="isManager" class="text-right"><span v-html="numberFormat(item.sum_agentWon)"></span></td>
            <td v-if="isManager" class="text-right"><span v-html="numberFormat(item.sum_agentCommission)"></span></td>
            <td v-if="isManager" class="text-right"><span v-html="numberFormat(item.sum_agentTotal)"></span></td>

            <td v-if="isManager" class="text-right"><span v-html="numberFormat(item.sum_ownerHold)"></span></td>
            <td v-if="isManager" class="text-right"><span v-html="numberFormat(item.sum_ownerWon)"></span></td>
            <td v-if="isManager" class="text-right"><span v-html="numberFormat(item.sum_ownerCommission)"></span></td>
            <td v-if="isManager" class="text-right"><span v-html="numberFormat(item.sum_ownerTotal)"></span></td>

            <td v-if="isManager" class="text-right"><span v-html="numberFormat(item.sum_companyWinloss)"></span></td>
            <td v-if="isManager" class="text-right"><span v-html="numberFormat(item.sum_ownerWinloss)"></span></td>
          </tr>
          <tr v-if="group.length > 0" class="alert-success">
            <th class="text-center">ยอดรวม {{webname(group[0]._id.webId)}}</th>
            <th class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].sales)"></span></th>
            <th class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].won)"></span></th>
            <th class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].balance)"></span></th>

            <th v-if="isManager" class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].agentHold)"></span></th>
            <th v-if="isManager" class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].agentWon)"></span></th>
            <th v-if="isManager" class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].agentCommission)"></span></th>
            <th v-if="isManager" class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].agentTotal)"></span></th>

            <th v-if="isManager" class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].ownerHold)"></span></th>
            <th v-if="isManager" class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].ownerWon)"></span></th>
            <th v-if="isManager" class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].ownerCommission)"></span></th>
            <th v-if="isManager" class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].ownerTotal)"></span></th>

            <th v-if="isManager" class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].companyWinloss)"></span></th>
            <th v-if="isManager" class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].ownerWinloss)"></span></th>
          </tr>
        </tbody>
        <tbody v-if="!items.length">
          <tr>
            <td :colspan="isManager?14:4" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tfoot v-if="items.length > 0" style="border-top: 3px double #ccc;">
          <tr style="font-size: 110%" class="alert-primary">
            <th class="text-center">รวม</th>
            <th class="text-right"><span v-html="numberFormat(total.sales)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.won)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.balance)"></span></th>

            <th v-if="isManager" class="text-right"><span v-html="numberFormat(total.agentHold)"></span></th>
            <th v-if="isManager" class="text-right"><span v-html="numberFormat(total.agentWon)"></span></th>
            <th v-if="isManager" class="text-right"><span v-html="numberFormat(total.agentCommission)"></span></th>
            <th v-if="isManager" class="text-right"><span v-html="numberFormat(total.agentTotal)"></span></th>

            <th v-if="isManager" class="text-right"><span v-html="numberFormat(total.ownerHold)"></span></th>
            <th v-if="isManager" class="text-right"><span v-html="numberFormat(total.ownerWon)"></span></th>
            <th v-if="isManager" class="text-right"><span v-html="numberFormat(total.ownerCommission)"></span></th>
            <th v-if="isManager" class="text-right"><span v-html="numberFormat(total.ownerTotal)"></span></th>

            <th v-if="isManager" class="text-right"><span v-html="numberFormat(total.companyWinloss)"></span></th>
            <th v-if="isManager" class="text-right"><span v-html="numberFormat(total.ownerWinloss)"></span></th>
          </tr>
        </tfoot>
      </table>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />
  </div>
</template>
<script>
import AppConfig from '@/configs/app.config.js'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import _ from 'lodash'

import Swal from 'sweetalert2'

import moment from '@/helpers/moment'

import ReportService from '@/services/ReportService'

import BarChart from '@/views/components/BarChart'
import CardDateFrom from '@/views/components/CardDateFrom'

export default {
  name: 'BranchReportAgent',
  components: {
    Loading,
    BarChart,
    CardDateFrom
  },
  data() {
    return {
      isLoading: false,
      dataDate: null,
      successDataDate: null,
      selectedBranch: null,
      data: {},
      branchFilters: [],
      webFilters: [],

      chartSelected: ['sales', 'wons'],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed: {
    isManager() {
      const LoggedInData = JSON.parse(localStorage.getItem(AppConfig.key.LoggedIn))
      return /*LoggedInData?.userRole === 'Owner' ||*/ LoggedInData?.role === 'Manager' || LoggedInData?.role === 'Admin'
    },
    dataDateText() {
      if(!this.successDataDate) {
        return '-'
      }

      const diff = moment(this.dataDate.end).diff(this.dataDate.start, 'd') + 1

      if(this.successDataDate.start === this.successDataDate.end) {
        return `${this.successDataDate.start} รวม ${diff} วัน`
      }else{
        return `${this.successDataDate.start} ถึง ${this.successDataDate.end} ข้อมูลทั้งหมด ${diff} วัน`
      }
    },
    Websites() {
      return this.data?.Websites || []
    },
    items() {
      if(!this.data?.Summaries)
        return []

      return this.data.Summaries.filter((item)=>{
        if(!this.branchFilters.length)
          return true

        return this.branchFilters.includes(item._id.accountId)
      })
      .filter((item)=>{
        if(!this.webFilters.length)
          return true

        return this.webFilters.includes(item._id.webId)
      })
    },
    groupItems() {
      return _.groupBy(_.sortBy(this.items, ['_id.webId', '_id.agentName']), '_id.webId')
    },
    groupTotals() {
      let totals = {}
      for(let id in this.groupItems) {
        totals[id] = this.groupItems[id].reduce((total, d)=>{

          total.sales += parseFloat(d.sum_sales)
          total.won += parseFloat(d.sum_won)
          total.balance += parseFloat(d.sum_balance)

          total.agentHold += parseFloat(d.sum_agentHold)
          total.agentWon += parseFloat(d.sum_agentWon)
          total.agentCommission += parseFloat(d.sum_agentCommission)
          total.agentTotal += parseFloat(d.sum_agentTotal)

          total.ownerHold += parseFloat(d.sum_ownerHold)
          total.ownerWon += parseFloat(d.sum_ownerWon)
          total.ownerCommission += parseFloat(d.sum_ownerCommission)
          total.ownerTotal += parseFloat(d.sum_ownerTotal)

          total.ownerWinloss += parseFloat(d.sum_ownerWinloss)
          total.companyWinloss += parseFloat(d.sum_companyWinloss)

          return total
        }, {
          sales: 0,
          won: 0,
          balance: 0,

          agentHold: 0,
          agentWon: 0,
          agentCommission: 0,
          agentTotal: 0,

          ownerHold: 0,
          ownerWon: 0,
          ownerCommission: 0,
          ownerTotal: 0,

          ownerWinloss: 0,
          companyWinloss: 0
        })
      }
      return totals
    },
    total() {
      return this.items.reduce((total, d)=>{

        total.sales += parseFloat(d.sum_sales)
        total.won += parseFloat(d.sum_won)
        total.balance += parseFloat(d.sum_balance)

        total.agentHold += parseFloat(d.sum_agentHold)
        total.agentWon += parseFloat(d.sum_agentWon)
        total.agentCommission += parseFloat(d.sum_agentCommission)
        total.agentTotal += parseFloat(d.sum_agentTotal)

        total.ownerHold += parseFloat(d.sum_ownerHold)
        total.ownerWon += parseFloat(d.sum_ownerWon)
        total.ownerCommission += parseFloat(d.sum_ownerCommission)
        total.ownerTotal += parseFloat(d.sum_ownerTotal)

        total.ownerWinloss += parseFloat(d.sum_ownerWinloss)
        total.companyWinloss += parseFloat(d.sum_companyWinloss)

        return total
      }, {
        sales: 0,
        won: 0,
        balance: 0,

        agentHold: 0,
        agentWon: 0,
        agentCommission: 0,
        agentTotal: 0,

        ownerHold: 0,
        ownerWon: 0,
        ownerCommission: 0,
        ownerTotal: 0,

        ownerWinloss: 0,
        companyWinloss: 0
      })
    },
    chartSelectOptions() {
      let options = []
      options.push({ text: 'ยอดขาย', value: 'sales' })
      options.push({ text: 'ยอดจ่าย', value: 'wons' })

      // if(this.isManager) {
      //   options.push({ text: 'เอเย่นต์', value: 'commission' })
      //   options.push({ text: 'บริษัทฯ', value: 'company' })
      // }

      return options
    },
    chartCollection() {

      const labels = this.items.map((item)=>{
        return item._id.agentName
      })

      let datasets = {}
      datasets.sales = {
        label: 'ยอดคีย์',
        backgroundColor: '#c3e6cb',
        data: this.items.map((item)=>{
          return Math.abs(item.sum_sales || 0)
        })
      }

      datasets.wons = {
        label: 'ยอดจ่าย',
        backgroundColor: '#f5c6cb',
        data: this.items.map((item)=>{
          return Math.abs(item.sum_won || 0)
        })
      }

      // datasets.commission = {
      //   label: 'เอเย่นต์',
      //   backgroundColor: '#bee5eb',
      //   data: this.items.map((item)=>{
      //     return Math.abs(item.sum_grandCommission || 0)
      //   })
      // }

      // datasets.company = {
      //   label: 'บริษัทฯ',
      //   backgroundColor: '#b8daff',
      //   data: this.items.map((item)=>{
      //     return Math.abs(item.sum_ownerTotal || 0)
      //   })
      // }

      return {
        labels: labels,
        datasets: this.chartSelected.map((select)=>{
          return datasets[select]
        })
      }
    },
    Branchs() {
      return this.data?.Branchs || []
    },
    branchOptions() {
      return this.Branchs.map((branch)=>{
        return {
          text: branch.name,
          value: branch._id
        }
      })
    },
    webOptions() {
      return this.Websites.map((item)=>{
        return {
          text: item.item_name,
          value: item._id
        }
      })
    }
  },
  methods: {
    searchData(params) {
      this.dataDate = params?.date
      this.selectedBranch = params?.branch

      this.isLoading = true
      ReportService.getAgents(this.dataDate, this.selectedBranch)
      .then((response)=>{
        if(response.status === 'success') {
          this.successDataDate = this.dataDate
          this.data = response.data
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    numberFormat(number, dec=2) {
      if(!number || number==='-')
        return '-'

      number = parseFloat(number).toFixed(dec)
      const numberText = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if(number > 0)
        return `<span class="text-success">${numberText}</span>`
      else if(number < 0)
        return `<span class="text-danger">${numberText}</span>`

      return numberText
    },
    dateFormat(arg) {
      return moment(arg).format("YYYY-MM-DD")
    },
    webname(id) {
      return this.Websites.find((web)=>{
        return web._id === id
      })?.item_name
    }
  },
  filters: {
    dateFormat(arg) {
      return moment(arg).format("YYYY-MM-DD")
    }
  }
}
</script>
<style lang="scss" scoped>
#agent-report {
  position: relative;
  padding-bottom: 50px;

  .view-info {
    cursor: pointer;
  }
}
</style>
